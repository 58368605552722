<template>
  <div>
    <!-- directive -->
    <b-img
      v-if="!validSource"
      ref="btnShow"
      class="cursor-pointer"
      :height="height"
      :width="width"
      :src="urlThumb"
      @click="loginValid"
    />
    <div
      v-if="validSource"
      v-viewer="options"
      class="images clearfix cursor-pointer"
    >
      <img
        :height="height"
        :width="width"
        :src="urlThumb"
        :data-src="source"
      >
    </div>
    <div>
      <b-modal
        :id="'modal-login-image' + documentId"
        hide-backdrop
        content-class="shadow"
        :title="$t('access')"
        size="xs"
        button-size="sm"
        centered
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        @close="cancelLogin"
      >
        <!-- :ok-title="$t('generic.access')" -->
        <validation-observer
          ref="loginForm"
        >
          <b-form @submit.prevent="toAccess">
            <b-form-group>
              <label for="password">{{ $t('loginMessages.password') }}</label>
              <validation-provider
                #default="{ errors }"
                name="password"
                vid="password"
                rules="required|min:8|password"
              >
                <b-form-input
                  v-model="form.password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  :placeholder="$t('loginMessages.password')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="float-right">

              <!-- Buttons cancel -->
              <b-button
                variant="secondary"
                size="sm"
                pill
                style="margin-left: 17px;"
                @click.stop="cancelLogin"
              >
                {{ $t('buttons.cancel') }}
              </b-button>

              <overlay-button :loading="isLoadingAccess">
                <b-button
                  variant="primary"
                  pill
                  size="sm"
                  style="margin-left: 17px;"
                  type="submit"
                  :style="colorPrimaryBtn"
                >
                  {{ $t('generic.toAccess') }}
                </b-button>
              </overlay-button>

            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </div>
</template>
<script>
// https://mirari.cc/v-viewer/
// https://github.com/mirari/v-viewer
// eslint-disable-next-line import/no-extraneous-dependencies
import 'viewerjs/dist/viewer.css'
import Vue from 'vue'
import VueViewer from 'v-viewer'
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import OverlayButton from '@/views/partials/OverlayButton.vue'

Vue.use(VueViewer)
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    OverlayButton,
    // Viewer,
  },
  props: {
    documentId: {
      type: Number,
      default: null,
    },
    urlThumb: {
      type: String,
      // eslint-disable-next-line global-require
      default: require('@/assets/images/icons/identification.png'),
    },
    height: {
      type: String,
      default: '130',
    },
    width: {
      type: String,
      default: '100',
    },
    permissionShow: {
      type: String,
      default: null,
    },
    permissionDownload: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      options: {
        url: 'data-src',
        navbar: false,
        fullscreen: true,
        inline: false,
        zoomable: true,
      },
      source: null,
      document: null,
      isLoadingAccess: false,
      form: {
        password: null,
      },
      validSource: false,
    }
  },
  computed: {
    ...mapGetters({
      colorPrimaryBtn: 'colorPrimaryBtn',
    }),
  },
  methods: {
    ...mapActions({
      validateViewPemissionByPassword: 'memberStore/validateViewPemissionByPassword',
      mediaFileShow: 'mediaFile/show',
    }),
    async toAccess() {
      this.isLoadingAccess = true
      await this.validateViewPemissionByPassword({
        password: this.form.password,
        permission: this.permissionShow,
      }).then(async () => {
        this.fetchDocument(this.documentId)
        this.isLoadingAccess = false
        this.$root.$emit('bv::hide::modal', `modal-login-image${this.documentId}`)
      }).catch(error => {
        this.responseCatch(error)
        this.$refs.loginForm.setErrors({
          password: [this.$t('password-is-incorrect')],
        })
      }).finally(() => {
        this.isLoadingAccess = false
      })
    },
    async loginValid() {
      this.$root.$emit('bv::show::modal', `modal-login-image${this.documentId}`)
    },
    async fetchDocument(docId) {
      this.show = true
      await this.mediaFileShow(docId).then(async response => {
        const file = new Blob([response.data], { type: response.data.type })
        const image64 = []
        image64.push(await this.blobToBase64(file))
        this.source = image64
        this.$viewerApi({
          images: this.source,
        })
        this.validSource = true
      }).catch(error => {
        this.responseCatch(error)
      }).finally(() => {
        this.show = false
        this.validSource = false
      })
    },
    blobToBase64(blob) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    },
    cancelLogin() {
      this.form.password = null
      this.$root.$emit('bv::hide::modal', `modal-login-image${this.documentId}`)
    },
  },
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
  .image {
    width: calc(20% - 10px);
    cursor: pointer;
    margin: 5px;
    display: inline-block;
  }
</style>
