var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_c('strong',[_vm._v(_vm._s(_vm.$t('clubs.assemblies.assemblyRegistration')))])]),_c('span',{staticClass:"i-text-request-info"},[_vm._v(" "+_vm._s(_vm.$t('corporate.create.textRequest'))+" ")]),_c('hr')]),_c('b-col',{attrs:{"md":"12"}},[_c('b-overlay',{class:_vm.applyOverlay ? 'p-2 mb-1' : 'mb-1',attrs:{"show":_vm.applyOverlay,"opacity":"1"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-center mt-1"},[_c('b-spinner',{attrs:{"type":"border","variant":"primary"}})],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('p',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.$t('loading'))+" ")])])]},proxy:true}])},[_c('b-row',[(!_vm.applyOverlay)?_c('b-col',{attrs:{"md":"12"}},[_c('validation-observer',{ref:"assemblyRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();_vm.updatingData = true}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"for":"assembly-incorporation-date"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.assemblies.assemblyDate'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"assemblyDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"assembly-incorporation-date","hide-header":"","locale":_vm.$i18n.locale,"placeholder":_vm.$t('clubs.assemblies.placeholder.selectAssemblyDate'),"state":errors.length > 0 ? false:null,"max":_vm.todayAss},model:{value:(_vm.assembly.incorporationDate),callback:function ($$v) {_vm.$set(_vm.assembly, "incorporationDate", $$v)},expression:"assembly.incorporationDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3046488093)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"assembly-instrument-number"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.instrumentNumber.labels.instrumentNumber'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"instrumentNumber","rules":"required|integer|min:1|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"assembly-instrument-number","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('clubs.instrumentNumber.placeholder.enterInstrument')},model:{value:(_vm.assembly.instrumentNumber),callback:function ($$v) {_vm.$set(_vm.assembly, "instrumentNumber", $$v)},expression:"assembly.instrumentNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2231639834)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"assembly-country-notary"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.instrumentNumber.labels.countryOfNotary'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"countryOfNotary","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"assembly-country-notary","state":errors.length > 0 ? false : null,"label":"name","reduce":function (option) { return option.id; },"options":_vm.countries,"placeholder":_vm.$t('generic.selectCountry')},on:{"input":function($event){return _vm.fetchStates(_vm.assembly.countryOfNotaryId)}},model:{value:(_vm.assembly.countryOfNotaryId),callback:function ($$v) {_vm.$set(_vm.assembly, "countryOfNotaryId", $$v)},expression:"assembly.countryOfNotaryId"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2882800522)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"assembly-city-notary"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.instrumentNumber.labels.stateOfNotary'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"stateOfNotary","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"assembly-city-notary","state":errors.length > 0 ? false : null,"reduce":function (option) { return option.id; },"label":"name","options":_vm.states,"placeholder":_vm.$t('generic.selectState'),"disabled":_vm.assembly.countryOfNotaryId == null},model:{value:(_vm.assembly.stateOfNotaryId),callback:function ($$v) {_vm.$set(_vm.assembly, "stateOfNotaryId", $$v)},expression:"assembly.stateOfNotaryId"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,537488433)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"assembly-notary-name"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.instrumentNumber.labels.notaryName'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"notaryName","rules":"required|max:255|nameRegex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"assembly-notary-name","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('clubs.instrumentNumber.placeholder.selectNotaryName')},model:{value:(_vm.assembly.notaryName),callback:function ($$v) {_vm.$set(_vm.assembly, "notaryName", $$v)},expression:"assembly.notaryName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,607326167)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"assembly-notary-number"}},[_vm._v(" "+_vm._s(_vm.$t('clubs.instrumentNumber.labels.notaryNumber'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"notaryNumber","rules":"required|integer|min:1|max:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"assembly-notary-number","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('clubs.instrumentNumber.placeholder.selectNotaryNumber')},model:{value:(_vm.assembly.notaryNumber),callback:function ($$v) {_vm.$set(_vm.assembly, "notaryNumber", $$v)},expression:"assembly.notaryNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,891327283)})],1)],1),(_vm.actions.name == 'edit')?_c('b-col',{attrs:{"md":"6"}},[_c('upload-file',{attrs:{"title":_vm.$t('generic.document'),"folder":"clubs","club-id":_vm.assembly.clubId,"file-title":_vm.assembly.documentFile ? _vm.assembly.documentFile.title : null},on:{"uploaded-file":function($event){return _vm.assignFileDinamic($event)}}})],1):_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_vm._v(" "+_vm._s(_vm.$t('generic.document'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t('generic.document'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"file-ass-input",attrs:{"type":"file","accept":"image/jpeg, image/png, image/jpg, application/pdf","browse-text":_vm.$t('buttons.explore'),"placeholder":_vm.$t('generic.uploadDocument'),"state":errors.length > 0 ? false:null,"no-drop":""},on:{"change":_vm.fileValidation},model:{value:(_vm.auxFile),callback:function ($$v) {_vm.auxFile=$$v},expression:"auxFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,418121728)})],1)],1),_c('b-col',{attrs:{"sm":"6","md":"4","lg":"3"}},[_c('label',{staticClass:"i-font-size-date",attrs:{"label-for":"assembly-current"}},[_vm._v(" "+_vm._s(_vm.$t('generic.markAsCurrent'))+" "),_c('br'),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},model:{value:(_vm.assembly.isCurrent),callback:function ($$v) {_vm.$set(_vm.assembly, "isCurrent", $$v)},expression:"assembly.isCurrent"}},[_c('span',[_vm._v(_vm._s(_vm.assembly.isCurrent ? _vm.$t('generic.yes') : _vm.$t('generic.not')))])])],1)],1)],1)]),_c('b-col',{attrs:{"md":"12"}},[_c('rich-text-edit-ass',{attrs:{"text-one":_vm.$t('clubs.assemblies.agreementsOfTheAssembly'),"text-two":'agreementsOfTheAssembly',"agreements":_vm.assembly.agreements,"updating-data":_vm.updatingData,"apply-edition-x":_vm.actions.name,"read-only-text":false},on:{"assign-text-edit":_vm.assignTextEdit}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('hr'),_c('b-button',{staticClass:"float-right ml-1",style:(_vm.colorPrimaryBtn),attrs:{"variant":"primary","pill":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t( 'buttons.save'))+" ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"secondary","pill":""},on:{"click":function($event){return _vm.$emit('change-type-view')}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")])],1)],1)],1)],1)],1):_c('b-col',{staticClass:"i-height-div",attrs:{"cols":"12"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }