<template>
  <b-overlay
    :show="applyOverlayD"
    :class="applyOverlayD ? 'p-2 mb-1' : 'mb-1'"
  >
    <template #overlay>
      <div class="d-flex align-items-center justify-content-center mt-1">
        <b-spinner
          type="border"
          variant="primary"
        />
      </div>
      <div class="d-flex justify-content-center">
        <p class="pt-1">
          {{ $t('loading') }}
        </p>
      </div>
    </template>
    <b-row v-if="!applyOverlayD">
      <!-- Asamblea -->
      <b-col md="12">
        <b-row>
          <b-col md="6">
            <h5 class="mt-1">
              <strong>{{ $t('clubs.assemblies.assemblyData') }}</strong>
            </h5>
          </b-col>
          <b-col md="6">
            <span
              v-if="nAssemblies > 1 && checkPermission(['DELETE_ASSEMBLY', 'ALLOW_ALL'])"
              class="float-right mt-1 ml-1"
            >
              {{ $t( 'buttons.delete') }}
              <b-button
                class="btn-icon rounded-circle"
                variant="danger"
                @click="$emit('delete-assembly', actions.assemblyId)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </span>
            <span
              v-if="checkPermission(['EDIT_ASSEMBLY', 'ALLOW_ALL'])"
              class="float-right mt-1"
            >
              {{ $t( 'buttons.edit') }}
              <b-button
                class="btn-icon rounded-circle"
                variant="secondary"
                @click="$emit('edit-assembly', actions.assemblyId)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </span>
          </b-col>
        </b-row>
        <hr style="margin-top: 8px;">
      </b-col>
      <b-col
        v-if="assemblyInfoD.instrument_number"
        md="12"
      >
        <b-list-group>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.assemblies.assemblyDate') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ assemblyInfoD.incorporation_date | formatDate() }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.countryOfNotary') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ assemblyInfoD.instrument_number.country.labelables[0].label }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.stateOfNotary') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ assemblyInfoD.instrument_number.state.name }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.notaryName') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ assemblyInfoD.instrument_number.notary_name }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.instrumentNumber.labels.notaryNumber') }}</strong>
              </b-col>
              <b-col md="6">
                <span class="float-left">{{ assemblyInfoD.instrument_number.notary_number }}</span>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('generic.document') }}</strong>
              </b-col>
              <b-col md="6">
                <span>. . .</span>
                <b-media
                  class="float-right"
                  no-body
                >
                  <span
                    class="i-c-pointer cursor-pointer float-right"
                    @click="showFile = !showFile"
                  >
                    {{ $t('generic.see') }}
                    <feather-icon
                      v-if="!showFile"
                      icon="FileIcon"
                      size="17"
                      style="padding-bottom: 2px"
                    />
                  </span>
                  <div
                    v-if="showFile == true"
                    class="ml-2"
                    style="display: flex;"
                  >
                    <div
                      v-if="checkPermission(['VIEW_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL']) && showFile == true"
                      class="float-right"
                      style="display: flex;"
                    >
                      <div v-if="isObject(assemblyInfoD.document_media_file) && assemblyInfoD.document_media_file.mime_type != 'application/pdf'">
                        <image-viewer
                          class="cursor-pointer mr-1"
                          :document-id="assemblyInfoD.document_file_hash"
                          :url-thumb="assemblyInfoD.document_media_file.url_thumb"
                          :height="'30'"
                          :width="'30'"
                          :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                          :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                        />
                      </div>
                      <div v-else>
                        <ViewerDocumentModal
                          class="cursor-pointer mr-1"
                          :title="assemblyInfoD.document_media_file ? assemblyInfoD.document_media_file.title : ''"
                          :document-id="assemblyInfoD.document_file_hash"
                          :height="'30'"
                          :width="'30'"
                          :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                          :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                        />
                      </div>
                      <div v-if="assemblyInfoD.document_file_hash != null && checkPermission(['DOWNLOAD_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL'])">
                        <span
                          class="float-right cursor-pointer"
                          @click="handleDownloadFile(assemblyInfoD.document_file_hash, assemblyInfo.document_media_file.title)"
                        >
                          <i
                            class="icon-0-icons-dark-download btn-cursor"
                            style="font-size: 20px;"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </b-media>
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col md="6">
                <strong class="float-left">{{ $t('clubs.assemblies.agreementsOfTheAssembly') }}</strong>
              </b-col>
              <b-col md="6">
                <span>. . .</span>
                <span
                  v-b-modal.modal-xl-rt-d
                  class="i-c-pointer float-right"
                >
                  {{ $t('generic.see') }}
                  <feather-icon
                    icon="EyeIcon"
                    size="17"
                    style="padding-bottom: 2px"
                  />
                </span>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <!--/ Asamblea -->
      <!-- Modal -->
      <b-modal
        id="modal-xl-rt-d"
        no-close-on-backdrop
        centered
        size="xl"
        :title="$t('clubs.assemblies.agreementsOfTheAssembly')"
      >
        <!-- texto enriquecido -->
        <rich-text-edit-detail-a
          ref="modal-xl-rtext-d"
          :text-one="$t('clubs.assemblies.agreementsOfTheAssembly')"
          :text-two="'agreementsOfTheAssembly'"
          :agreements="assemblyInfoD.agreements"
          :updating-data="false"
          :apply-edition-x="'edit'"
          :read-only-text="true"
        />
        <!--/ texto enriquecido -->
        <template #modal-footer>
          <div class="w-100" />
        </template>
      </b-modal>
      <!--/ Modal -->
      <login-modal
        :permission="validPermission"
        @change="toAccess"
      />
    </b-row>
    <b-row v-else>
      <b-col
        md="12"
        class="i-height-div"
      />
    </b-row>
  </b-overlay>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapActions } from 'vuex'
import { isObject, downloadFile } from '@core/utils/utils'
import { BRow, BCol, BListGroup, BListGroupItem, BButton, BModal, VBModal, BOverlay } from 'bootstrap-vue'
import assemblyService from '@/services/assemblyService'
import ImageViewer from '@/views/member/ImageViewer.vue'
import LoginModal from '@/views/member/LoginModal.vue'
import RichTextEditDetailA from '@/views/partials/editors/RichTextEdit.vue'
import ViewerDocumentModal from '@/views/member/ViewerDocumentModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BButton,
    BModal,
    BOverlay,
    RichTextEditDetailA,
    ImageViewer,
    ViewerDocumentModal,
    LoginModal,
  },

  directives: {
    'b-modal': VBModal,
  },

  props: {
    actions: {
      type: Object,
      default: () => {},
    },
    nAssemblies: {
      type: Number,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      validPermission: null,
      showFile: false,
      assemblyInfoD: {},
      applyOverlayD: false,
    }
  },

  created() {
    this.fetchAssemblyInfo()
  },

  methods: {
    downloadFile,
    isObject,
    ...mapActions({ mediaFileShow: 'mediaFile/show' }),

    fetchAssemblyInfo() {
      this.applyOverlayD = true

      assemblyService.getAssemblyInfo(this.actions.assemblyId).then(({ data }) => {
        this.assemblyInfoD = data.data
        this.applyOverlayD = false
      }).catch(error => {
        this.applyOverlayD = false
        this.responseCatch(error)
      })
    },

    async toAccess(permissionData) {

    },

    async handleDownloadFile(fileHash, title) {
      this.isLoading = true
      await this.mediaFileShow(fileHash).then(response => {
        const { data: dataDocument } = response
        this.downloadFile(dataDocument, title)
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.responseCatch(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style scoped>
.i-c-pointer:hover {
  color: #6abeff;
}
</style>
