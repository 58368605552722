<template>
  <b-row>
    <b-col cols="12">
      <h5>
        <strong>{{ $t('clubs.assemblies.assemblyRegistration') }}</strong>
      </h5>
      <span class="i-text-request-info">
        {{ $t('corporate.create.textRequest') }}
      </span>
      <hr>
    </b-col>
    <!-- form -->
    <b-col md="12">
      <b-overlay
        :show="applyOverlay"
        :class="applyOverlay ? 'p-2 mb-1' : 'mb-1'"
        opacity="1"
      >
        <template #overlay>
          <div class="d-flex align-items-center justify-content-center mt-1">
            <b-spinner
              type="border"
              variant="primary"
            />
          </div>
          <div class="d-flex justify-content-center">
            <p class="pt-1">
              {{ $t('loading') }}
            </p>
          </div>
        </template>
        <b-row>
          <b-col
            v-if="!applyOverlay"
            md="12"
          >
            <validation-observer ref="assemblyRules">
              <b-form @submit.prevent="updatingData = true">
                <b-row>
                  <b-col md="6">
                    <b-form-group for="assembly-incorporation-date">
                      {{ $t('clubs.assemblies.assemblyDate') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="assemblyDate"
                        rules="required"
                      >
                        <b-form-datepicker
                          id="assembly-incorporation-date"
                          v-model="assembly.incorporationDate"
                          hide-header
                          :locale="$i18n.locale"
                          :placeholder="$t('clubs.assemblies.placeholder.selectAssemblyDate')"
                          :state="errors.length > 0 ? false:null"
                          :max="todayAss"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="assembly-instrument-number">
                      {{ $t('clubs.instrumentNumber.labels.instrumentNumber') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="instrumentNumber"
                        rules="required|integer|min:1|max:20"
                      >
                        <b-form-input
                          id="assembly-instrument-number"
                          v-model="assembly.instrumentNumber"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('clubs.instrumentNumber.placeholder.enterInstrument')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="assembly-country-notary">
                      {{ $t('clubs.instrumentNumber.labels.countryOfNotary') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="countryOfNotary"
                        rules="required"
                      >
                        <v-select
                          id="assembly-country-notary"
                          v-model="assembly.countryOfNotaryId"
                          :state="errors.length > 0 ? false : null"
                          label="name"
                          :reduce="option => option.id"
                          :options="countries"
                          :placeholder="$t('generic.selectCountry')"
                          @input="fetchStates(assembly.countryOfNotaryId)"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="assembly-city-notary">
                      {{ $t('clubs.instrumentNumber.labels.stateOfNotary') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="stateOfNotary"
                        rules="required"
                      >
                        <v-select
                          id="assembly-city-notary"
                          v-model="assembly.stateOfNotaryId"
                          :state="errors.length > 0 ? false : null"
                          :reduce="option => option.id"
                          label="name"
                          :options="states"
                          :placeholder="$t('generic.selectState')"
                          :disabled="assembly.countryOfNotaryId == null"
                        >
                          <div slot="no-options">
                            {{ $t('generic.noRecordsFound') }}
                          </div>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="assembly-notary-name">
                      {{ $t('clubs.instrumentNumber.labels.notaryName') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="notaryName"
                        rules="required|max:255|nameRegex"
                      >
                        <b-form-input
                          id="assembly-notary-name"
                          v-model="assembly.notaryName"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('clubs.instrumentNumber.placeholder.selectNotaryName')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="assembly-notary-number">
                      {{ $t('clubs.instrumentNumber.labels.notaryNumber') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        name="notaryNumber"
                        rules="required|integer|min:1|max:12"
                      >
                        <b-form-input
                          id="assembly-notary-number"
                          v-model="assembly.notaryNumber"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('clubs.instrumentNumber.placeholder.selectNotaryNumber')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="actions.name == 'edit'"
                    md="6"
                  >
                    <upload-file
                      :title="$t('generic.document')"
                      folder="clubs"
                      :club-id="assembly.clubId"
                      :file-title="assembly.documentFile ? assembly.documentFile.title : null"
                      @uploaded-file="assignFileDinamic($event)"
                    />
                  </b-col>
                  <b-col
                    v-else
                    md="6"
                  >
                    <b-form-group>
                      {{ $t('generic.document') }}
                      <span class="text-danger">*</span>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('generic.document')"
                        rules="required"
                      >
                        <b-form-file
                          ref="file-ass-input"
                          v-model="auxFile"
                          type="file"
                          accept="image/jpeg, image/png, image/jpg, application/pdf"
                          :browse-text="$t('buttons.explore')"
                          :placeholder="$t('generic.uploadDocument')"
                          :state="errors.length > 0 ? false:null"
                          no-drop
                          @change="fileValidation"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="4"
                    lg="3"
                  >
                    <label
                      label-for="assembly-current"
                      class="i-font-size-date"
                    >
                      {{ $t('generic.markAsCurrent') }} <br>
                      <b-row>
                        <b-col md="4">
                          <b-form-checkbox
                            v-model="assembly.isCurrent"
                            checked="true"
                            name="check-button"
                            switch
                            inline
                          >
                            <span>{{ assembly.isCurrent ? $t('generic.yes') : $t('generic.not') }}</span>
                          </b-form-checkbox>
                        </b-col>
                      </b-row>
                    </label>
                  </b-col>
                  <b-col md="12">
                    <rich-text-edit-ass
                      :text-one="$t('clubs.assemblies.agreementsOfTheAssembly')"
                      :text-two="'agreementsOfTheAssembly'"
                      :agreements="assembly.agreements"
                      :updating-data="updatingData"
                      :apply-edition-x="actions.name"
                      :read-only-text="false"
                      @assign-text-edit="assignTextEdit"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <hr>
                    <b-button
                      class="float-right ml-1"
                      variant="primary"
                      pill
                      type="submit"
                      :style="colorPrimaryBtn"
                    >
                      {{ $t( 'buttons.save') }}
                    </b-button>
                    <b-button
                      class="float-right"
                      variant="secondary"
                      pill
                      @click="$emit('change-type-view')"
                    >
                      {{ $t('buttons.cancel') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
            <!--/ form -->
          </b-col>
          <b-col
            v-else
            cols="12"
            class="i-height-div"
          />
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapGetters, mapActions } from 'vuex'
import { validatorFileSize, validatorFileType } from '@core/utils/validations/validators'
import { BRow, BCol, BFormGroup, BForm, BFormInput, BFormDatepicker, BButton } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer, min, max, nameRegex } from '@validations'
import { destroyMediaFile } from '@/services/mediaFileService'
import countryService from '@/services/countryService'
import assemblyService from '@/services/assemblyService'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RichTextEditAss from '@/views/partials/editors/RichTextEdit.vue'
import UploadFile from '@/views/member/components/UploadFile.vue'

export default {
  components: {
    UploadFile,
    RichTextEditAss,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
    BFormDatepicker,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },

  directives: {
    Ripple,
  },

  props: {
    actions: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      auxFile: [],
      statesList: [],
      countriesList: [],
      applyDeleteFile: false,
      previousFileHash: null,
      assembly: {
        id: null,
        clubId: null,
        incorporationDate: null,
        instrumentNumber: null,
        countryOfNotaryId: '',
        stateOfNotaryId: '',
        notaryName: null,
        notaryNumber: null,
        agreements: null,
        documentFile: null,
        documentFileId: null,
        status: true,
        isCurrent: true,
      },
      todayAss: new Date(),
      applyOverlay: false,
      applyEdition: false,
      updatingData: false,
      required, // validation
      integer, // validation
      min, // validation
      max, // validation
      nameRegex, // validation
    }
  },

  computed: {
    ...mapGetters({ assemblyInfo: 'assemblyInfo', colorPrimaryBtn: 'colorPrimaryBtn' }),

    countries() {
      const list = this.countriesList.map(c => ({ id: c.hash, name: c.labelables[0].label }))
      return list
    },

    states() {
      const list = this.statesList.map(c => ({ id: c.hash, name: c.name }))
      return list
    },
  },

  watch: {
    '$i18n.locale': function () {
      this.fetchCountries()
    },

    'assembly.countryOfNotaryId': function (item, old) {
      if (item != null) {
        if (item != old && old != null && old !== '') {
          this.assembly.stateOfNotaryId = null
          this.statesList = []
        }
        this.fetchStates(item)
      } else {
        this.assembly.stateOfNotaryId = null
        this.statesList = []
      }
    },
  },

  mounted() {
    this.fetchCountries()
    this.assignAssembly()
  },

  methods: {
    validatorFileSize,
    validatorFileType,
    ...mapActions(['fetchAssemblyInfo', 'saveAssembly', 'updateAssembly']),

    fetchCountries() {
      countryService.getCountries().then(({ data }) => {
        this.countriesList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    fetchStates(id) {
      const filtersObj = { country_hash: id }

      countryService.getStates(filtersObj).then(({ data }) => {
        this.statesList = data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    assignAssembly() {
      if (this.actions.name == 'edit') {
        this.applyOverlay = true
        assemblyService.getAssemblyInfo(this.actions.assemblyId).then(({ data }) => {
          const assemblyData = data.data
          this.assembly.id = assemblyData.hash
          this.assembly.clubId = assemblyData.club_hash
          this.assembly.incorporationDate = assemblyData.incorporation_date
          this.assembly.instrumentNumber = assemblyData.instrument_number.instrument_number
          this.assembly.countryOfNotaryId = assemblyData.instrument_number.country_of_notary_hash
          this.assembly.stateOfNotaryId = assemblyData.instrument_number.state_of_notary_hash
          this.assembly.notaryName = assemblyData.instrument_number.notary_name
          this.assembly.notaryNumber = assemblyData.instrument_number.notary_number
          this.assembly.agreements = assemblyData.agreements
          this.assembly.documentFile = assemblyData.document_media_file
          this.assembly.documentFileId = assemblyData.document_media_file.hash
          this.assembly.status = assemblyData.status
          this.assembly.isCurrent = assemblyData.is_current
          this.applyOverlay = false
        }).catch(error => {
          this.applyOverlay = false
          this.responseCatch(error)
        })
      }
    },

    assignTextEdit(pText, successRule) {
      this.assembly.agreements = pText
      this.assembly.documentFile = this.actions.name == 'edit' ? this.assembly.documentFile : this.auxFile

      this.$refs.assemblyRules.validate().then(success => {
        if (success && successRule) {
          if (this.actions.name == 'edit') {
            const formData = {
              id: this.actions.assemblyId,
              club_id: parseInt(this.actions.clubId, 10),
              incorporation_date: this.assembly.incorporationDate,
              instrument_number: this.assembly.instrumentNumber,
              country_of_notary_id: this.assembly.countryOfNotaryId,
              state_of_notary_id: this.assembly.stateOfNotaryId,
              notary_name: this.assembly.notaryName,
              notary_number: this.assembly.notaryNumber,
              agreements: this.assembly.agreements,
              document_file: this.assembly.documentFile,
              document_file_id: this.assembly.documentFileId,
              status: this.assembly.status ? 1 : 0,
              is_current: this.assembly.isCurrent ? 1 : 0,
            }

            assemblyService.updateAssembly(formData).then(({ data }) => {
              this.responseSuccessUpdate(data.message)
              this.applyDeleteFile ? this.deleteFileX() : this.$emit('change-type-view')
            }).catch(error => {
              this.responseCatch(error)
            })
          } else {
            const formData = new FormData()
            formData.append('club_id', parseInt(this.actions.clubId, 10))
            formData.append('incorporation_date', this.assembly.incorporationDate)
            formData.append('instrument_number', this.assembly.instrumentNumber)
            formData.append('country_of_notary_id', this.assembly.countryOfNotaryId)
            formData.append('state_of_notary_id', this.assembly.stateOfNotaryId)
            formData.append('notary_name', this.assembly.notaryName)
            formData.append('notary_number', this.assembly.notaryNumber)
            formData.append('agreements', this.assembly.agreements)
            formData.append('document_file', this.assembly.documentFile)
            formData.append('status', this.assembly.status ? 1 : 0)
            formData.append('is_current', this.assembly.isCurrent ? 1 : 0)

            assemblyService.storeAssembly(formData).then(({ data }) => {
              this.responseSuccessCreate(data.message)
              this.$emit('change-type-view')
            }).catch(error => {
              this.responseCatch(error)
            })
          }
        } else {
          this.updatingData = false
        }
      })
    },

    assignFileDinamic(responseFile) {
      if (this.assembly.documentFileId == null) {
        this.assembly.documentFile = responseFile
        this.assembly.documentFileId = responseFile.hash
      } else {
        this.previousFileHash = this.assembly.documentFileId
        this.applyDeleteFile = true
        this.assembly.documentFile = responseFile
        this.assembly.documentFileId = responseFile.hash
      }
    },

    deleteFileX() {
      destroyMediaFile(this.previousFileHash).then(response => {
        this.$emit('change-type-view')
      }).catch(error => {
        this.responseCatch(error)
      })
    },

    fileValidation(event) {
      if (event.target.file !== 'undefined') {
        const fileX = event.target.files[0]
        // const validSize = this.validatorFileSize(fileX)
        const validType = this.validatorFileType(fileX)

        // if (!validSize) {
        //   this.$refs['file-ass-input'].reset()
        //   this.showError(`${this.$t('the-file-size-exceeds-the-allowed-size')}: 2MB`)
        // }

        if (!validType) {
          this.$refs['file-ass-input'].reset()
          this.showError(`${this.$t('only-documents-in-PDF-and-image-format-are-allowed')}`)
        }
      } else this.$refs['file-ass-input'].reset()
    },
  },
}
</script>
