<template>
  <div>
    <b-modal
      id="modal-login"
      hide-backdrop
      content-class="shadow"
      :title="$t('access')"
      size="xs"
      button-size="sm"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      @close="cancelLogin"
    >
      <!-- :ok-title="$t('generic.access')" -->
      <validation-observer
        ref="loginForm"
      >
        <b-form @submit.prevent="toAccess">
          <b-form-group>
            <label for="password">{{ $t('loginMessages.password') }}</label>
            <validation-provider
              #default="{ errors }"
              name="password"
              vid="password"
              rules="required|min:8|password"
            >
              <b-form-input
                v-model="form.password"
                :state="errors.length > 0 ? false : null"
                type="password"
                :placeholder="$t('loginMessages.password')"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="float-right">

            <!-- Buttons cancel -->
            <b-button
              variant="secondary"
              size="sm"
              pill
              style="margin-left: 17px;"
              @click.stop="cancelLogin"
            >
              {{ $t('buttons.cancel') }}
            </b-button>

            <overlay-button :loading="isLoadingAccess">
              <b-button
                variant="primary"
                pill
                size="sm"
                style="margin-left: 17px;"
                type="submit"
                :style="colorPrimaryBtn"
              >
                {{ $t('generic.toAccess') }}
              </b-button>
            </overlay-button>

          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import OverlayButton from '@/views/partials/OverlayButton.vue'

export default {
  name: 'LoginModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    OverlayButton,
  },
  props: {
    permission: {
      type: String,
      default: null,
    },
    fileId: {
      type: Number,
      default: null,
    },
    fileTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoadingAccess: false,
      form: {
        password: null,
      },
      params: {},
    }
  },
  computed: {
    ...mapGetters({
      colorPrimaryBtn: 'colorPrimaryBtn',
    }),
  },

  methods: {
    ...mapActions({
      validateViewPemissionByPassword: 'memberStore/validateViewPemissionByPassword',
    }),
    async toAccess() {
      this.isLoadingAccess = true
      await this.validateViewPemissionByPassword({
        password: this.form.password,
        permission: this.permission,
      }).then(async () => {
        this.$emit('change', { permission: this.permission, fileId: this.fileId, fileTitle: this.fileTitle })
        this.$root.$emit('bv::hide::modal', 'modal-login')
        this.isLoadingAccess = false
      }).catch(error => {
        this.isLoadingAccess = false
        this.responseCatch(error)
        this.$refs.loginForm.setErrors({
          password: [this.$t('password-is-incorrect')],
        })
      }).finally(() => {
        this.isLoadingAccess = false
      })
    },
    cancelLogin() {
      this.form.password = null
      this.$root.$emit('bv::hide::modal', 'modal-login')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
