<template>
  <div>
    <!-- v-b-modal="'document' + documentId" -->
    <b-img
      ref="btnShow"
      class="cursor-pointer"
      :height="height"
      :width="width"
      :src="urlThumb"
      @click="loginValid"
    />
    <!-- :src="require('@/assets/images/icons/pdf.png')" -->
    <!-- v-model="showModal" -->
    <b-modal
      :id="'document' + documentId"
      cancel-variant="secondary"
      hide-footer
      centered
      size="lg"
      :title="title"
      @close="cancelDoc"
    >
      <b-overlay
        :show="show"
        rounded="sm"
        no-fade
      >
        <b-container class="bv-example-row">
          <b-row>
            <b-col>
              <!-- bg-variant="light" -->
              <b-card
                no-body
                class="card-browser-states m-0"
              >

                <!-- body -->
                <b-card-body>
                  <div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      @click="rotate -= 90"
                    >
                      <feather-icon
                        icon="RotateCcwIcon"
                      />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      @click="rotate += 90"
                    >
                      <feather-icon
                        icon="RotateCwIcon"
                      />
                    </b-button>
                    <b-button
                      v-if="checkPermission([permissionDownload, 'ALLOW_ALL'])"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      @click="downloadFile(document, title)"
                    >
                      <feather-icon
                        icon="DownloadIcon"
                      />
                    </b-button>
                  </div>
                  <div>
                    <pdf
                      v-for="i in numPages"
                      :key="i"
                      ref="pdf"
                      :src="src"
                      :page="i"
                      :rotate="rotate"
                      style="border: .3px solid  #efefef"
                    />
                  </div>
                </b-card-body>
              <!--/ body -->
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </b-modal>
    <div>
      <b-modal
        :id="'modal-login' + documentId"
        hide-backdrop
        content-class="shadow"
        :title="$t('access')"
        size="xs"
        button-size="sm"
        centered
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        @close="cancelLogin"
      >
        <!-- :ok-title="$t('generic.access')" -->
        <validation-observer
          ref="loginForm"
        >
          <b-form @submit.prevent="toAccess">
            <b-form-group>
              <label for="password">{{ $t('loginMessages.password') }}</label>
              <validation-provider
                #default="{ errors }"
                name="password"
                vid="password"
                rules="required|min:8|password"
              >
                <b-form-input
                  v-model="form.password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  :placeholder="$t('loginMessages.password')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="float-right">

              <!-- Buttons cancel -->
              <b-button
                variant="secondary"
                size="sm"
                pill
                style="margin-left: 17px;"
                @click.stop="cancelLogin"
              >
                {{ $t('buttons.cancel') }}
              </b-button>

              <overlay-button :loading="isLoadingAccess">
                <b-button
                  variant="primary"
                  pill
                  size="sm"
                  style="margin-left: 17px;"
                  type="submit"
                  :style="colorPrimaryBtn"
                >
                  {{ $t('generic.toAccess') }}
                </b-button>
              </overlay-button>

            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </div>
</template>

<script>
// https://www.npmjs.com/package/vue-pdf
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import OverlayButton from '@/views/partials/OverlayButton.vue'
import { downloadFile } from '@core/utils/utils'
import pdf from 'vue-pdf'
import checkPermission from '@/auth/permissions'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'ViewerDocumentModal',
  components: {
    pdf,
    ValidationProvider,
    ValidationObserver,
    OverlayButton,
    // LoginModal,
  },
  directives: {
    Ripple,
  },
  props: {
    documentId: {
      type: Number,
      default: null,
    },
    urlThumb: {
      type: String,
      // eslint-disable-next-line global-require
      default: require('@/assets/images/icons/pdf.png'),
    },
    title: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: '130',
    },
    width: {
      type: String,
      default: null,
    },
    permissionShow: {
      type: String,
      default: null,
    },
    permissionDownload: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      src: null,
      numPages: undefined,
      show: false,
      rotate: 0,
      document: null,
      isLoadingAccess: false,
      form: {
        password: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      colorPrimaryBtn: 'colorPrimaryBtn',
    }),
  },
  methods: {
    downloadFile,
    checkPermission,
    ...mapActions({
      validateViewPemissionByPassword: 'memberStore/validateViewPemissionByPassword',
      mediaFileShow: 'mediaFile/show',
    }),
    async toAccess() {
      this.isLoadingAccess = true
      await this.validateViewPemissionByPassword({
        password: this.form.password,
        permission: this.permissionShow,
      }).then(async () => {
        this.showModal(this.documentId)
        this.isLoadingAccess = false
        this.$root.$emit('bv::hide::modal', `modal-login${this.documentId}`)
      }).catch(error => {
        this.responseCatch(error)
        this.$refs.loginForm.setErrors({
          password: [this.$t('password-is-incorrect')],
        })
      }).finally(() => {
        this.isLoadingAccess = false
      })
    },
    async loginValid() {
      this.$root.$emit('bv::show::modal', `modal-login${this.documentId}`)
    },
    async showModal(docId) {
      this.show = true
      await this.fetchDocument(docId)
      this.$root.$emit('bv::show::modal', `document${docId}`, '#btnShow')
    },
    async fetchDocument(docId) {
      this.show = true
      await this.mediaFileShow(docId).then(response => {
        const { data: dataDocument, data: { type } } = response
        this.document = dataDocument
        this.handleCretaeLoadinfTask(dataDocument, type)
      }).catch(error => {
        this.responseCatch(error)
      }).finally(() => {
        this.show = false
      })
    },
    async handleCretaeLoadinfTask(dataDocument, type) {
      this.show = true
      const file = new Blob([dataDocument], { type })

      await this.blobToBase64(file).then(response => {
        this.$nextTick(() => {
          this.src = pdf.createLoadingTask(response)
          // eslint-disable-next-line no-shadow
          this.src.promise.then(pdf => {
            this.numPages = pdf.numPages
            this.show = false
          })
        })
      }).catch(error => {
        this.responseCatch(error)
      }).finally(() => {
        this.show = false
      })
    },
    blobToBase64(blob) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    },
    cancelDoc() {
      this.$root.$emit('bv::hide::modal', `document${this.documentId}`, '#btnShow')
    },
    cancelLogin() {
      this.form.password = null
      this.$root.$emit('bv::hide::modal', `modal-login${this.documentId}`)
    },
  },
}

</script>
