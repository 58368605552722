<template>
  <b-overlay
    :show="loading"
    rounded
    opacity="0.6"
    spinner-small
    :spinner-variant="variant"
    class="d-inline-block"
  >
    <!-- Content -->
    <slot />
  </b-overlay>
</template>

<script>
import {
  BOverlay,
} from 'bootstrap-vue'

export default {
  name: 'OverlayButton',
  components: {
    BOverlay,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      // codeSpinner,
    }
  },
}
</script>
